<template>
  <div class="refundOrder-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    <el-dialog top="30vh" width="480px" :visible.sync="reviewDialogVisible"  :close-on-click-modal="false">
      <div class="review-title">审核退款</div>
      <div>
        <div class="review-tips">退款金额<span style="color:red">￥{{refundPrice}}</span>，确定同意退款吗？</div>
        <div style="color:#aaa">该操作不可逆，请谨慎操作。同意退款后，该笔订单将原路退还至用户支付账户。</div>
      </div>
      <div class="review-btn">
        <el-button @click="openRejectDialog()">驳回</el-button>
        <el-button type="primary" @click="agreeRefund()" :loading="agreeLoading">同意退款</el-button>
      </div>
    </el-dialog>
    <el-dialog top="30vh" width="480px" :visible.sync="rejectDialogVisible"  :close-on-click-modal="false"  @close="closeRejectDialog()">
      <div class="review-title">驳回退款</div>
      <div>
        <div class="review-tips">退款金额<span style="color:red">￥{{refundPrice}}</span>，确定同意退款吗？</div>
        <div style="color:#aaa">该操作不可逆，请谨慎操作。同意退款后，该笔订单将原路退还至用户支付账户。</div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="76px" class="demo-ruleForm">
          <el-form-item label="驳回原因" prop="refundReason">
            <el-input type="textarea" v-model="ruleForm.refundReason" placeholder="填写驳回原因" maxlength="24" show-word-limit :rows="3"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="review-btn">
        <el-button @click="closeRejectDialog()">关闭</el-button>
        <el-button type="danger" @click="rejectRefund()" :loading="rejectLoading">驳回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import cabinetUtil from "@utils/cabinetUtil.js"
  let validOrderId = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持输入大小写字母，数字，限制1~32位字符
      let reg = /^[A-z0-9]{1,32}$/;
      if (!reg.test(value)) {
        callback(new Error("订单号格式不对"));
      } else {
        callback();
      }
    }
  };
  export default {
    data() {
      let _this = this;
      return {
        opt: {
          title: "退款订单",
          search: [
            {
              key: "id",
              label: "退款单号",
              maxlength: 32,
              rules: [{
                validator: validOrderId,
                trigger: ['blur']
              }]
            },
            {
              key: "orderId",
              label: "支付订单号",
              maxlength: 32,
              rules: [{
                validator: validOrderId,
                trigger: ['blur']
              }]
            },
            {
              key: "merchantId",
              label: "商家名称",
              type: 'remoteSearchById',
              opt: {
                list: [],
                change(data) {
                  _this.getStoreList(data)
                }
              }
            }, {
              key: "groupId",
              label: "门店",
              type: "remoteSearchById",
              opt: {
                list: []
              }
            },
            {
              label: "货柜MAC",
              key: "counterMac",
            },{
              key: "state",
              label: "退款状态",
              type: "select",
              opt: {
                list: [{value: 0 ,label:'待审核'},{value: 1 ,label:'退款中'},{value: 2 ,label:'已退款'},{value: 3 ,label:'退款失败'},{value: 4 ,label:'已驳回'},],
              }
            },
            {
              key: "date",
              label: "申请退款时间",
              type: "time-range"
            },
          ],
          columns: [
            {
              label: "退款单号",
              key: "id",
              align: "center"
            },
            {
              label: "支付订单号",
              key: "orderId",
              align: "center"
            },
            {
              label: "商家名称",
              key: "merchantName",
              align: "center"
            },
            {
              label: "货柜MAC",
              key: "counterMac",
              align: "center"
            },
            {
              label: "门店",
              key: "groupName",
              align: "center"
            },
            {
              label: "退款金额（元）",
              key: "realRefundPriceStr",
              align: "center"
            },
            {
              label: "申请退款时间",
              key: "applyRefundTime",
              align: "center"
            },
            {
              label: "退款状态",
              key: "stateStr",
              align: "center",
              type: "diff-color",
              opt: {
                style: 'color:red', //设置颜色
                key: 'colorFlag' //改变颜色标识
              }
            },
            {
              label: "操作",
              key: "action",
              type: "action-back",
              opt: {
                list: [
                  {
                    label: "审核退款",
                    on(row){
                      _this.reviewRefund(row)
                    }
                  },{
                  label: "详情",
                  on(row) {
                    _this.setRefundInfo(row);
                    _this.$router.push({
                      path: "/main/refundOrder/detail",
                      query: {
                        orderId: row.orderId,
                        id: row.id,
                      }
                    })
                  }
                }]
              }
            }
          ],
        },
        merchantFlag: false,
        reviewDialogVisible: false,
        rejectDialogVisible: false,
        refundPrice: '',
        refundId: '',
        ruleForm: {
          refundReason: ''
        },
        rules: {
          refundReason: [
            { required: true, message: '请填写驳回原因', trigger: 'blur',}
          ]
        },
        agreeLoading: false,
        rejectLoading: false
      };
    },
    created() {
      console.log("refundOrder-list created!!");
    },
    activated() {
      console.log("refundOrder-list activated!!");
      this.merchantFlag = this.getLoginType('loginType');
      if (this.merchantFlag) {
        this.opt.search.forEach((item, index) => {
          if (item.key == 'merchantId') {
            this.opt.search.splice(index, 1);
          }
        })
        this.opt.columns.forEach((item, index) => {
          if (item.key == 'merchantName') {
            this.opt.columns.splice(index, 1);
          }
        })
      } else {
        this.opt.search.forEach((item, index) => {
          if (item.key == 'merchantId') {
            cabinetUtil.getMerchantList().then(res => {
              item.opt.list = res;
            })
          }
        })
      }
    },
    deactivated() {
      this.reviewDialogVisible = false;
      this.rejectDialogVisible = false;
    },
    methods: {
      onGet(opt) {
        let dto = {
          current: opt.skip,
          pageSize: opt.limit,
          storeId: this.cache.getLS('userInfo').merchantId,
          refundStartTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
          refundEndTime: opt.searchForm['date'] ? this.format(opt.searchForm['date'][1]) : null,
          ...opt.searchForm
        };
        let url = ''
        if(this.merchantFlag){
          url = "mall-service/mall/api/web/query/v1/store/order-refund/page";
        }else{
          url = "mall-service/mall/api/web/query/v1/platform/order-refund/page";
        }
        this.post(url, dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data.data = res.data.data.list;
          res.data.data.list.forEach(item => {
            item.stateStr = ["待审核","退款中", "已退款", "退款失败","已驳回"][item.state];
            item.colorFlag = false;
            item.action = [false,true]
            if(this.merchantFlag && item.state == 0) {
              item.action = [true,true]
            }else{
              item.action = [false,true]
            }
            if (item.state == 3) {
              item.colorFlag = true;
            }
            item.orderPriceStr = item.orderPrice.toFixed(2);
            item.realRefundPriceStr = item.realRefundPrice.toFixed(2);
            if (item.groupName) {
              item.groupName = item.groupName;
            } else {
              item.groupName = '--';
            }
            item.merchantName = item.merchantName ? item.merchantName : '--';
          });
          opt.cb(res.data.data);
        });
        if(opt.searchForm.merchantId) {
          this.getStoreList(opt.searchForm.merchantId);
        }else{
          this.getStoreList();
        }
        
      },
      setRefundInfo(data) {
        this.cache.set('refundInfo', data)
      },
      //获取门店列表
      getStoreList(data) {
        let dto = {
          currentPage: 1,
          size: 99999,
        };
        if(data) {
          dto.merchantId = data
        }
        this.post("/mall-service/v1/counterStores/getAllList", dto, {
          isUseResponse: true,
        }).then(res => {
          let storeList = res.data.data;
          storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
          storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
          this.opt.search.forEach(item => {
            if (item.key == 'groupId') {
              item.opt.list = storeList;
            }
          })
        });
      },
      reviewRefund(row) {
        this.refundPrice = row.realRefundPrice
        this.refundId = row.id
        this.reviewDialogVisible = true
        this.agreeLoading = false
      },
      agreeRefund() {
        this.agreeLoading = true
        let dto = {
          refundId: this.refundId,
          storeId: this.cache.getLS('userInfo').merchantId
        }
        this.post("mall-service/mall/api/web/v1/agree-refund",dto,{
          isUseResponse: true
        }).then(res => {
          if(res.data.code == 0) {
              this.$message({
                message: '审核通过',
                type: "success"
            });
            this.$refs.myList.get();
            this.reviewDialogVisible = false
          }
          this.agreeLoading = false
        }).catch(error =>{
          this.agreeLoading = false
        })
      },
      openRejectDialog() {
        this.rejectDialogVisible = true
        this.rejectLoading = false
      },
      rejectRefund() {
          this.$refs['ruleForm'].validate((valid) => {
              if (valid) {
                this.rejectLoading = true
                let dto = {
                  reason: this.ruleForm.refundReason,
                  refundId: this.refundId
                }
                this.post("mall-service/mall/api/web/v1/reject-refund",dto,{
                  isUseResponse: true
                }).then(res => {
                  if(res.data.code == 0) {
                    this.$message({
                        message: '驳回成功',
                        type: "success"
                    });
                    this.$refs.myList.get();
                    this.reviewDialogVisible = false
                    this.rejectDialogVisible = false
                  }
                  this.rejectLoading = false
                }).catch(error =>{
                    this.rejectLoading = false
                })
              }
          });
      },
      closeRejectDialog(){
        this.rejectDialogVisible = false
        this.$refs['ruleForm'].resetFields();
      }
    }
  };
</script>
<style lang="scss" scoped>
.review-tips{
  font-size: 18px;
  margin-top: 14px;
  margin-bottom: 10px
}
.review-btn{
  display: flex;
  justify-content: end;
  margin-top: 10px;
}

.review-title{
  font-size: 20px;
  font-weight: bold;
}
</style>